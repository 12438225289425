import React from 'react';

import { ContextData } from '../utils/interfaces';

/*
 * Initial data from context
 */
const initContext: ContextData = {
    userInfo: {},
};

export const AppContext = React.createContext(initContext);

/**
 * Labels for the Dealers View
 */
export const dealersLabels = {
    associatedDealers: 'Concesionarias asociadas a tu usuario',
    haveAccountYetLogIn: '¿Ya tienes una cuenta? Inicia sesión',
    haveAnotherAccount: '¿Tienes otra cuenta? Inicia sesión',
    btnBack: 'Regresar',
    btnNext: 'Continuar',
    dealership: 'Concesionarias',
    email: 'Correo electrónico registrado *',
    emptyDealers:
        'No hay concesionarias asociadas a tu usuario, favor de contactar a tu Gerente de zona.',
    heroTitle: 'Plataforma VWIB',
    heroDescription: 'Bienvenidos',
    heroIconClass: 'user-logged-in-02',
    dealers: 'Selecciona una opción *',
    validateDealer: 'Por favor selecciona un dealer',
    successSaveLegal: 'Se ha guardado la información del usuario correctamente',
    toast: {
        error: 'Ha ocurrido un error, por favor intente más tarde',
    },
};

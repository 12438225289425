import { ApiGatewayClientConfig, KeycloakConfig } from '@dh/keycloak-auth';

/**
 * Initial configuration for the keycloak instance, this substitute the usual
 * keycloak.json that you normal put in /public
 */
export const keycloakConfig: KeycloakConfig = {
    realm: process.env.REACT_APP_REALM || 'dealers',
    url:
        process.env.REACT_APP_URL ||
        'https://int.identity.digitalhub.vwfs.io/auth',
    clientId: process.env.REACT_APP_CLIENT_ID || 'alondra',
};

/**
 * Dealer Online back API credentials
 */
export const apiGatewayClientConfig: ApiGatewayClientConfig = {
    region: 'ca-central-1',
    invokerUrl:
        process.env.REACT_APP_API_URL ||
        'https://v6wh1i8soi.execute-api.ca-central-1.amazonaws.com/int/insurance',
    authorization: '',
};
/**
 * Namespace default for uuid
 */
export const nameSpace = 'bb5d0ffa-9a4c-4d7c-8fc2-0a7d2220ba45';
/**
 * Role mapping from keycloak
 */
export const insuranceRoles = 'alondra_rol';

/**
 * Roles definition for the application
 * [Keycloak role name in token] = [Application role name]
 */
export enum Roles {
    CONC_OPER_COT = 'CONC-OPER-COT',
    CONC_OPER_EMISI = 'CONC-OPER-EMISI',
    CONC_OPER_EJEC_EMISI = 'CONC-OPER-EJEC-EMISI',
    CONC_CAC_OPER = 'CONC-CAC-OPER',
    SELLER = 'SELLER',
}

/**
 * Alondra url for callback
 */
export const alondraCallbackUrl =
    process.env.REACT_APP_API_ALONDRA_URL ||
    'https://int.plataformavwib.com/app/?type=concesionario';

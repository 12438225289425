import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect, withRouter } from 'react-router-dom';

const Login = withRouter(() => {
    const { keycloak } = useKeycloak();

    // TODO: capture real `from` from router
    const { from } = { from: { pathname: '/' } };

    useEffect(() => {
        if (keycloak && !keycloak.authenticated) {
            keycloak.login();
        }
    });

    if (keycloak && keycloak.authenticated) return <Redirect to={from} />;

    return <div>redirecting</div>;
});

export default Login;

/**
 * This array includes all roles allowed at Applications or its fuctionality.
 */
import { allowedHomeRoles } from './allowedRoles';
import { Dealers } from '../views/Dealers';
import { Register } from '../views/Register';
import { RouteDefinition } from './routes.type';

/**
 * This object defines the navigation routes of the system and includes
 * path, component to be render, exact (for exact match see React Router) and
 * the allowed roles to consume the path.
 *
 * Add here new paths for navigation
 */
export const PortalRoutes: Array<RouteDefinition> = [
    {
        component: Dealers,
        path: '/',
        exact: true,
        label: '',
        allowedRoles: allowedHomeRoles,
    },
    {
        component: Register,
        path: '/register',
        label: 'register',
    },
];

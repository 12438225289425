/**
 * Labels for the Registration View
 */
export const registerLabels = {
    associatedDealers: 'Concesionarias asociadas a tu usuario',
    haveAccountYetLogIn: '¿Ya tienes una cuenta? Inicia sesión',
    haveAnotherAccount: '¿Tienes otra cuenta? Inicia sesión',
    btnBack: 'Regresar',
    btnRegistry: 'Regístrate',
    btnNext: 'Continuar',
    dealership: 'Concesionaria',
    email: 'Correo electrónico registrado *',
    emptyDealers: 'No hay concesionarias asociadas a tu usuario',
    errorHeaderRegister: 'Hubo un problema con tu registro',
    errorHeaderLogin: 'Hubo un problema con tu acceso',
    errorRegisterUser:
        'Ocurrió un error al crear el usuario, intente más tarde.',
    errorCatalog:
        'Ocurrió un error al cargar los catálogos, intente más tarde.',
    errorTypes: {
        publicDomain: 'Correo con dominio público',
        invalidDomain: 'Correo con dominio no autorizado por VW',
        invalidRole: 'Rol inválido',
    },
    headerForm: 'No se encontró un rol asignado',
    heroTitle: 'Plataforma VWIB',
    heroDescription: 'Bienvenidos',
    heroIconClass: 'user-logged-in-02',
    setSellerRol: 'Se te asignará el rol Seller ¿Aceptas este rol?',
    invalidDomain:
        'No es posible realizar tu registro con un correo de dominio público, por favor ' +
        'inténtalo nuevamente ingresando tu correo corporativo autorizado por VWFS.',
    invalidUser:
        'El correo registrado para tu acceso en Alondra no se encuentra autorizado. Por favor ' +
        'contacta a tu Gerente de Zona VWFS para que valide que este correo electrónico puedas utilizarlo en tu acceso. \n\n' +
        'Cuando tu Gerente de Zona te notifique que ya fue autorizado, solo debes iniciar sesión con el correo ' +
        'electrónico y contraseña registrado para accesar.',
    retentionManager: 'Retention Manager',
    salesPoint: 'Punto de venta',
    /*sellerFormTitle:
        'Ingresa la siguiente información para continuar con tu registro',
    sellerPrivacyNotice:
        'Los datos que nos proporcionaste no entran dentro de la protección de la ley federal de protección de datos ' +
        'personales en posesión de los particulares y demás disposiciones aplicables, con fundamento en el artículo 5, ' +
        'Fracción II del reglamento aplicable, sin embargo, los mismos serán tratados confidencialmente.',*/
    signUpAgain: 'Regístrate nuevamente',
    unregisteredUser:
        'El correo utilizado para tu registro no se encuentra autorizado, por favor ' +
        'contacta a tu Gerente de Zona VWFS para que actualice tu correo electrónico.',
    validateDealerships:
        'Es necesario tener concesionadas asociadas a tu usuario para completar el registro',
    nextSteps:
        'Comunicate con tu Gerente de Zona para que te diga los siguientes pasos.',
    successSaveLegal: 'Se ha guardado la información del usuario correctamente',
    serviceErrors: {
        domainNotAllowed: 'The email domain is not allowed',
        emailNotFound: 'The email was not found in the dealers directory',
    },
};

import {
    BronsonButton,
    BronsonFormInput,
    BronsonFormRadio,
    BronsonSpinner,
} from '@dh/bronson-react';
import React, { useEffect, useState } from 'react';

import { defaultUserError } from '../../views/Register/register.cons';
import { registerLabels } from '../../views/Register/register.labels';
import { UserType } from '../../utils/enums/user-types.enum';
import {
    RegistrationActions,
    UserDataProfileType,
} from '../../views/Register/interfaces/register';

export const RegistrationForm: React.FC<{
    userEmail: string;
    type: UserType;
    registering: boolean;
    actions: RegistrationActions;
}> = ({ userEmail, type, registering, actions }) => {
    const [userData, setUserData] = React.useState<UserDataProfileType>({
        seller: '',
        type: type,
    });
    const [userError, setUserError] = useState(defaultUserError);
    const [error, setError] = useState<string>();

    useEffect(() => {
        if (userData.type === UserType.ENF) {
            setUserError({
                errorMessage: registerLabels.invalidUser,
                hasError: true,
            });
        }
        if (userData.type === UserType.DNA) {
            setUserError({
                errorMessage: registerLabels.invalidDomain,
                hasError: true,
            });
        }
    }, [userData.type]);

    /**
     * This method validates the user's data and sends
     * it to the backend to complete the registration.
     */
    const validateDecision = () => {
        if (userData.seller === 'true') {
            actions.setRole(userData);
        } else {
            actions.sendNotification();
            setError(registerLabels.nextSteps);
        }
    };

    /**
     * This method returns the label for left button
     */
    const textBtnLeft = () => {
        if (userData.type === UserType.WOR) {
            return registerLabels.haveAccountYetLogIn;
        } else {
            return registerLabels.haveAnotherAccount;
        }
    };

    /**
     * This method returns the label for right button
     */
    const textBtnRight = () => {
        if (userData.type === UserType.WOR) {
            return registerLabels.btnNext;
        } else {
            return registerLabels.btnRegistry;
        }
    };

    /**
     * This method returns the header form
     */
    const getHeader = () => {
        if (userError.hasError) {
            return userData.type === UserType.DNA
                ? registerLabels.errorHeaderRegister
                : registerLabels.errorHeaderLogin;
        } else {
            return registerLabels.headerForm;
        }
    };

    return (
        <div
            id='registration-view'
            className='o-component-wrapper u-mt-xsmall@s u-mt-large u-mb-large'
        >
            <div className='o-page-wrap o-page-wrap--xsmall'>
                <h5 className='u-text-brand u-text-center u-mv'>
                    {getHeader()}
                </h5>
                <hr className='c-divider' />
                {
                    <>
                        <div className='o-fieldset u-mb'>
                            {userData.type !== UserType.WOR && (
                                <div className='o-fieldset__row'>
                                    <BronsonFormInput
                                        defaultValue={userEmail}
                                        errorMessage={userError.errorMessage}
                                        hasError={userError.hasError}
                                        isDisabled={true}
                                        label={registerLabels.email}
                                        maxLength={100}
                                        type='text'
                                    />
                                </div>
                            )}
                            {userData.type === UserType.WOR && (
                                <div className='o-fieldset__row'>
                                    {registerLabels.setSellerRol}
                                    <div className='o-inline-group u-mt u-mb-large'>
                                        <div className='o-inline-group__item'>
                                            <BronsonFormRadio
                                                key={'rol-true'}
                                                elementId={'rol-true'}
                                                label='Acepto'
                                                defaultValue='true'
                                                group={'role'}
                                                onChange={(change) => {
                                                    setUserData({
                                                        ...userData,
                                                        seller: change.value,
                                                    });
                                                }}
                                                checked={
                                                    userData.seller === 'true'
                                                }
                                            />
                                        </div>
                                        <div className='o-inline-group__item'>
                                            <BronsonFormRadio
                                                key={'rol-false'}
                                                elementId={'rol-false'}
                                                label='No acepto'
                                                defaultValue='false'
                                                group={'role'}
                                                onChange={(change) => {
                                                    setUserData({
                                                        ...userData,
                                                        seller: change.value,
                                                    });
                                                }}
                                                checked={
                                                    userData.seller === 'false'
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>{error}</div>
                                </div>
                            )}
                        </div>
                    </>
                }
                <div className='o-layout o-layout--equal-height o-layout--right u-mv'>
                    <div className='o-layout__item  u-2/3 u-1/1@xs'>
                        <div className='o-button-container  o-button-container--left'>
                            <BronsonButton
                                dataComponent='btn-cancel'
                                click={actions.logout}
                                config={{
                                    isDisable: false,
                                    types: ['isLink'],
                                }}
                                label={textBtnLeft()}
                            />
                        </div>
                    </div>
                    <div className='o-layout__item  u-1/3 u-1/1@xs'>
                        <div className='o-button-container  o-button-container--right'>
                            {registering ? (
                                <BronsonSpinner
                                    config={{
                                        spinnerWrapperClassModifier:
                                            'c-spinner--small',
                                        showSpinner: true,
                                    }}
                                />
                            ) : (
                                <BronsonButton
                                    dataComponent='btn-confirm'
                                    label={textBtnRight()}
                                    click={validateDecision}
                                    config={{
                                        types: [''],
                                        isDisable:
                                            !!error || userData.seller === '',
                                        elementClassModifier:
                                            'o-button-container__button',
                                    }}
                                ></BronsonButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

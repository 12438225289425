import { Roles } from '../config';

/**
 * Roles allowed Home
 */
export const allowedHomeRoles = [
    Roles.CONC_OPER_COT,
    Roles.CONC_OPER_EMISI,
    Roles.CONC_OPER_EJEC_EMISI,
    Roles.CONC_CAC_OPER,
    Roles.SELLER,
];

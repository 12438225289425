/**
 * Return terms component
 * @param param0 title
 * @returns Terms and conditions
 */
/* eslint-disable react/no-unescaped-entities */

export const GeneralTermsAndConditions: React.FC<{
    title: string;
}> = ({ title }) => (
    <div style={{ textAlign: 'justify' }}>
        <h3>{title}</h3>
        <p className='u-pb-small'>
            Los presentes Términos y Condiciones de Uso (en adelante denominados
            como los “Términos y Condiciones”) regulan el acceso y uso del
            contenido por Volkswagen Financial Services. Al entrar, navegar y/o
            utilizar el Sitio, el "Usuario" reconoce y acepta y se obliga a
            todos y cada uno de los Términos y las Condiciones siguientes:
        </p>
        <h4>Objeto</h4>
        <p className='u-pb-small'>
            Los presentes Términos y Condiciones regulan el uso y navegación a
            través de la Plataforma, así como los Servicios ofrecidos y el
            Contenido confidencial a través del mismo.
        </p>
        <p className='u-pb-small'>
            Para efectos de estos Términos y Condiciones se entenderá por
            “Usuario” a cualquier persona física mayor de edad o persona moral
            mexicana o cuyo domicilio o establecimiento se encuentren ubicados
            en México, que acceda a la Plataforma y/o a cualquiera de sus
            páginas derivadas para conocer su contenido, solicitar, cargar,
            modificar, extraer, enviar o descargar información.
        </p>
        <p className='u-pb-small'>
            Esta Aplicación y algunos sitios desde la cual se puede
            redireccionar al Usuario, contiene o puede llegar a contener
            información relativa a las empresas del Grupo Volkswagen o así como
            sus productos, servicios y programas, incluyendo publicidad,
            promociones, otra información y otro contenido, que puede
            presentarse en forma de texto, datos, música, sonidos, gráficos,
            imágenes, fotografías, videos, software o de otra manera.
        </p>
        <h4>Navegación</h4>
        <p className='u-pb-small'>
            Para poder acceder a esta Plataforma, el Usuario debe estar
            autorizado por Volkswagen Financial Services previamente, en
            términos de sus respectivos Avisos de Privacidad y de acuerdo a las
            políticas que para tal efecto le dé a conocer y previamente el
            Usuario cumpla con ellas.
        </p>
        <h4>Prohibiciones</h4>
        <p className='u-pb-small'>
            El Usuario tiene prohibido suplantar la identidad, es decir, accesar
            con cuentas falsas o en nombre de otro Usuario, así como suprimir y
            modificar la información personal de los clientes. Si Volkswagen
            Financial Services tiene motivos razonables para sospechar que la
            información proporcionada por el Usuario antes del uso o durante el
            uso de la Plataforma es falsa, imprecisa o incompleta, Volkswagen
            Financial Services se reserva el derecho a denegar, con o sin previo
            aviso, el uso de la Plataforma.
        </p>
        <p className='u-pb-small'>
            Al acceder a y utilizar la Plataforma Digital, el Usuario se
            compromete a NO:
        </p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Infringir los derechos de propiedad intelectual y de privacidad,
                entre otros, los derechos de autor (copyright), los derechos
                sobre la base de datos, las marcas registradas o el know how de
                terceros;
            </li>
            <li className='u-mv-xsmall'>
                Descargar, enviar, transmitir o almacenar material que:
                <ul
                    className='c-ul u-ph-small'
                    style={{ listStyleType: 'disc' }}
                >
                    <li className='u-mv-xsmall'>
                        Infrinja las obligaciones contractuales o de
                        confidencialidad del Usuario,
                    </li>
                    <li className='u-mv-xsmall'>
                        Perjudique o interfiera en las aplicaciones normales de
                        la Plataforma, como el envío o la transmisión de virus,
                        gusanos o troyanos.
                    </li>
                </ul>
            </li>
            <li className='u-mv-xsmall'>
                Contravenir, o intentar contravenir, las medidas de seguridad de
                la Plataforma;
            </li>
            <li className='u-mv-xsmall'>
                Acceder o intentar acceder a la cuenta o al login de las
                terceras personas o empresas indicadas en el Plataforma;
            </li>
            <li className='u-mv-xsmall'>
                Utilizar la Plataforma Digital de forma no autorizada o para
                alguna actividad delictiva;
            </li>
        </ul>
        <h4>Utilización del sitio y obligaciones</h4>
        <p className='u-pb-small'>El Usuario deberá:</p>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Utilizar la Plataforma únicamente para los fines legalmente
                permitidos.
            </li>
            <li className='u-mv-xsmall'>
                Proteger los datos personales que conforman la Información de
                los Clientes.
            </li>
            <li className='u-mv-xsmall'>
                Enviar solamente material sobre el que el Usuario tenga los
                correspondientes derechos o licencia para hacerlo;
            </li>
            <li className='u-mv-xsmall'>
                Utilizar su propio criterio, precaución y sentido común al
                gestionar la información conseguida a través de la Plataforma.
            </li>
            <li className='u-mv-xsmall'>
                Mantener la confidencialidad de dichos cuenta de acceso y
                contraseña;
            </li>
            <li className='u-mv-xsmall'>
                Actualizar y comprobar frecuentemente su contraseña; y
            </li>
            <li className='u-mv-xsmall'>
                g. Notificar inmediatamente a Volkswagen Financial Services, si
                tiene conocimiento del uso no autorizado de su cuenta o de
                cualquier vulneración de las medidas de seguridad.
            </li>
        </ul>
        <h4>Responsabilidades</h4>
        <ul
            className='c-ul u-ph-small'
            style={{ listStyleType: 'lower-alpha' }}
        >
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services no asume responsabilidad alguna
                por el funcionamiento o cualesquier tipo de daños y/o perjuicios
                que el Usuario estime puedan derivarse de la interacción de esta
                Plataforma con su dispositivo móvil, equipo de cómputo o
                cualquier otro equipo, ni derivado de presencia de virus, de
                programas malicioso o lesivos en los contenidos, ni por
                cualquier otro elemento que pudiera ser introducido por
                cualquier tercero violando los controles que tenemos en nuestra
                Plataforma, ni por cualquier otra aplicación que pretenda
                suplantar ésta.
            </li>
            <li className='u-mv-xsmall'>
                La Plataforma ha sido revisada y aprobada para que funcione
                correctamente. Sin embargo no se garantiza la disponibilidad y
                continuidad total o parcial de ésta o las secciones que la
                integran. Volkswagen Financial Services no asume responsabilidad
                alguna por cualquier daño o perjuicio derivado de la falta de
                continuidad o disponibilidad de conexión a la Aplicación.
            </li>
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services no asume responsabilidad alguna
                por cualquier daño o perjuicio derivado de:
                <ul
                    className='c-ul u-ph-small'
                    style={{ listStyleType: 'disc' }}
                >
                    <li className='u-mv-xsmall'>
                        El uso inadecuado por parte del Usuario respecto de los
                        elementos que conforman su contenido.
                    </li>
                    <li className='u-mv-xsmall'>
                        El funcionamiento de cualquiera de los enlaces y/o ligas
                        (links) contenidos en esta Aplicación y/o la calidad,
                        licitud, fiabilidad y utilidad de los productos,
                        servicios, información o cualesquiera elementos de los
                        contenidos en otros sitios web y/o redes sociales y/o
                        aplicaciones vinculadas desde o con esta Plataforma.
                    </li>
                </ul>
            </li>
            <li className='u-mv-xsmall'>
                Esta Plataforma es para uso del Usuario previamente registrado.
                Por el acceso y uso de esta Plataforma, en ningún momento ni
                bajo ningún concepto el Usuario podrá considerar que goza de
                cualquier derecho o licencias para el uso y/o explotación de
                cualquiera de los elementos que conforman el contenido de esta
                Plataforma. Cualquier otro uso distinto al estrictamente
                mencionado de la Plataforma por parte del Usuario requiere de
                autorización previa por Volkswagen Financial Services por
                escrito.
            </li>
            <li className='u-mv-xsmall'>
                La información contenida en esta Plataforma, es de carácter
                confidencial y Volkswagen Financial Services ha tomado todas las
                medidas para que el Usuario no haga el mal uso de la
                información.
            </li>
            <li className='u-mv-xsmall'>
                Los productos y/o servicios ofertados, así como los beneficios
                y/o promociones otorgadas que aparezcan publicitados o referidas
                en esta Plataforma, son responsabilidad sólo de la empresa que
                los esté ofertando u otorgando.
            </li>
            <li className='u-mv-xsmall'>
                Volkswagen Financial Services se reserva el derecho a
                interrumpir, restringir, modificar o suspender en cualquier
                momento o de vez en cuando y de forma temporal o permanente el
                funcionamiento de esta Plataforma, ya sea parcialmente o en su
                totalidad, con o sin previo aviso.
            </li>
        </ul>
        <h4>Derechos de Propiedad Industrial e Intelectual</h4>
        <p className='u-pb-small'>
            Todo el contenido de esta Plataforma es propiedad de Volkswagen
            Financial Services (cada uno o en conjunto) Por tanto, los derechos
            sobre dicho contenido se encuentran protegidos por las leyes
            mexicanas aplicables y/o por el derecho internacional.
        </p>
        <p className='u-pb-small'>
            Asimismo se encuentran protegidos los derechos sobre las palabras,
            imágenes, colores, que conforman cada uno del (los) logotipo(s) que
            identifican a cada marca del Grupo Volkswagen (no importando sus
            colores, tipos de representaciones gráficas, forma, tamaños u otras
            características) y cualquier otra palabra y cualquier otro elemento
            que sirva para distinguir a los productos, siluetas, imágenes,
            servicios, marcas, lemas publicitarios, videos o cualesquiera otros
            elementos de las empresas de Audi y de Volkswagen, que se encuentren
            contenidos actualmente o se inserten en el futuro. Ninguno de estos
            elementos puede ser utilizado sin el previo permiso por escrito de
            su(s) titular(es), quedando por tanto prohibida su reproducción,
            modificación, distribución, transmisión, re-publicación, exhibición
            o ejecución, así como cualquier otro uso del contenido de esta
            Aplicación en alguna otra página de Internet, red informática,
            aplicación y de otra forma, incluso en la publicidad y anuncios en
            relación con la conformación de esta Plataforma.
        </p>
        <p className='u-pb-small'>
            Queda prohibido al Usuario intentar obtener información, mensajes,
            archivos de sonido y/o de imagen (incluyendo fotografías,
            grabaciones, videos, dibujos, software, etc.) o cualquier otro
            elemento de esta Plataforma empleando cualquier medio distinto a los
            puestos expresamente a disposición del Usuario para tal fin.
        </p>
        <p className='u-pb-small'>
            De igual forma el Usuario deberá abstenerse de intentar realizar, o
            realizar cualquier tipo de adición, supresión, transformación, o
            cualquier otra forma de alteración al contenido de esta Plataforma,
            así como de realizar ingeniería inversa, descompilar, desensamblar o
            intentar descubrir el código fuente de la misma.
        </p>
        <p className='u-pb-small'>
            Al usar la Plataforma, el Usuario acepta cumplir con todas las
            leyes, regulaciones y otros requisitos legales relacionados con el
            acceso y uso de la Plataforma. Cualquier uso no autorizado del
            contenido de esta Plataforma será violatorio de la Ley de la
            Propiedad Industrial, la Ley Federal del Derecho de Autor, las leyes
            civiles, mercantiles, penales y/o demás leyes nacionales o normas
            internacionales aplicables, y dará derecho a cualquier afectado
            requerir la indemnización correspondiente por parte del infractor e
            implementar cualquier medida para evitar que se le causen o
            continúen causándosele daños y perjuicios.
        </p>
        <p className='u-pb-small'>
            El Usuario acepta informar de manera inmediata a Volkswagen
            Financial Services si sospecha que se ha producido una actividad
            ilegal, fraudulenta o abusiva, o cualquier otra violación de estos
            Términos y Condiciones, incluyendo cualquier acceso no autorizado a
            la Aplicación.
        </p>
        <h4>Protección de Datos Personales</h4>
        <p className='u-pb-small'>
            Para poder ingresar y hacer uso de la Plataforma, el Usuario debe
            haber leído y aceptado el Aviso de Privacidad contenido en la página
            principal. La información completa sobre el uso de los datos
            personales se describe en dicho Aviso de Privacidad que está
            disponible para su consulta a través de esta Plataforma en el
            apartado Aviso de Privacidad.
        </p>
        <h4>Competencia, jurisdicción y derecho aplicable</h4>
        <p className='u-pb-small'>
            El Usuario al hacer uso de esta Plataforma acepta que está sujeta a
            las leyes federales aplicables en los Estados Unidos Mexicanos y que
            cualquier controversia que se derive del uso e interacción con esta
            la Aplicación o de cualquier cuestión relacionada con esta, se
            dirimirá ante los tribunales competentes de la ciudad de Puebla,
            Puebla, renunciando expresamente a cualquier otra jurisdicción que
            pudiera corresponderles por razón de su domicilio presente o futuro
            o por cualquier otra razón.
        </p>
        <h4>Actualización de Términos y Condiciones</h4>
        <p className='u-pb-small'>
            Volkswagen Financial Services se reserva expresamente el derecho de
            actualizar en cualquier momento los presentes Términos y
            Condiciones.
        </p>
        <h4>Divisibilidad</h4>
        <p className='u-pb-small'>
            Si cualquier disposición de estos Términos y Condiciones es o
            llegare a ser contraria a la ley, nula o no aplicable, no afectará
            la validez y aplicabilidad de cualquier otra disposición.
        </p>
        <h4>Contacto</h4>
        <p className='u-pb-small'>
            En caso de que el Usuario tenga cualquier pregunta, comentario o
            solicitud relacionada con esta Plataforma, podrá ponerse en contacto
            directo con VWFS.
        </p>
        <p className='u-pb-small'>
            <b>
                Volkswagen Financial Services, es el nombre comercial de las
                entidades del grupo financiero Volkswagen que integran a
                Volkswagen Leasing, S.A. de C.V., Volkswagen Bank, S.A.
                Institución de Banca Múltiple y Volkswagen Insurance Brokers,
                Agente de Seguros y de Fianzas, S.A. de C.V. con domicilio en
                Autopista México-Puebla Km 116 más 900, San Lorenzo Almecatla,
                C.P. 72700 Cuautlancingo, Puebla, México. Entidades debidamente
                constituidas de acuerdo a las Leyes Mexicanas.
            </b>
        </p>
        <p className='u-pb-small'>Ultima actualización 10/05/2023.</p>
    </div>
);

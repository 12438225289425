import { BronsonToastProps } from '@dh/bronson-react';

import { generalLabels } from '../labels/generic-message.labels';
import { ToastNotificationType } from '../enums';

/**
 * Toast data error
 */
export const toastError: BronsonToastProps = {
    isVisible: true,
    content: generalLabels.toastNotifications.service.error,
    type: ToastNotificationType.Error,
    isClosable: true,
    autoClose: true,
};

/**
 * Toast data initialization
 */
export const toastInit: BronsonToastProps = {
    isVisible: false,
    content: '',
    type: 'default',
    isClosable: true,
};

/**
 * User type enum.
 * WOR: without rol
 * NOR: with rol
 * ENF: email not found in the dealers directory
 * DNA: domain not allowed
 */
export enum UserType {
    NOR,
    ENF,
    DNA,
    WOR,
}

/**
 * Generic message labels.
 */
export const generalLabels = {
    toastNotifications: {
        service: {
            error: 'Ha ocurrido un error, intenta más tarde',
            detail: 'Ha ocurrido un error -> [error]',
        },
    },
    modal: {
        edit: {
            button: 'Confirmar',
            content: 'Al hacer esto modificarás permanentemente este registro.',
            errorMessage: 'Error al intentar editar el registro -> [error]',
            successMessage: 'Se ha editado correctamente el registro',
            title: '¿Estás seguro que deseas guardar los cambios?',
        },
        cancel: {
            button: 'Cancelar',
        },
    },
};

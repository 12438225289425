import { useKeycloak } from '@react-keycloak/web';
import { v5 as uuidv5 } from 'uuid';
import {
    BronsonSpinner,
    BronsonToastNotification,
    BronsonToastProps,
} from '@dh/bronson-react';
import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../context/app.context';
import { AppContextInterface } from '../../utils/interfaces';
import { dealersLabels } from './dealers.labels';
import { DealersList } from '../../components/Dealers';
import { DealersService } from './dealers.service';
import { Hero } from '../../components/Hero';
import heroImage from '../../assets/img/hero/register-dealer.jpg';
import { alondraCallbackUrl, nameSpace } from '../../config';
import { toastError, toastInit, zero } from '../../utils/constants';

/**
 * View responsible for providing information of the home
 */
export const Dealers: React.FC = () => {
    const { keycloak } = useKeycloak();
    const { userInfo } = useContext(AppContext) as AppContextInterface;
    const [loading, setLoading] = useState<boolean>(true);
    const [dealer, setDealer] = useState<string>('');
    const [registering, setRegistering] = useState<boolean>(false);

    const [toastNotificationConfig, setToastNotificationConfig] =
        useState<BronsonToastProps>(toastInit);

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length !== zero) {
            setLoading(false);
        }
    }, [userInfo]);

    const setUserAttribute = () => {
        const dealerService = new DealersService(keycloak);
        const uuid = uuidv5(`${userInfo.email}-${dealer}`, nameSpace);
        const attributes = {
            attributes: {
                group: [dealer],
                login: [uuid],
            },
        };
        setRegistering(true);
        dealerService
            .setAttribute(userInfo.id, attributes)
            .then(() => {
                setRegistering(false);
                window.location.href = alondraCallbackUrl;
            })
            .catch(() => {
                setRegistering(false);
                setToastNotificationConfig({
                    ...toastError,
                    setVisibility: () =>
                        setToastNotificationConfig({
                            ...toastNotificationConfig,
                            isVisible: false,
                        }),
                });
            });
    };

    return (
        <div>
            <BronsonToastNotification {...toastNotificationConfig} />
            <Hero
                title={dealersLabels.heroTitle}
                description={dealersLabels.heroDescription}
                iconClass={dealersLabels.heroIconClass}
                heroImage={heroImage}
            />
            <main className='o-main'>
                <section>
                    {loading ? (
                        <BronsonSpinner
                            config={{
                                spinnerWrapperClassModifier:
                                    'c-spinner--center u-m-large',
                                showSpinner: true,
                            }}
                        />
                    ) : (
                        <DealersList
                            registering={registering}
                            dealerships={userInfo.dealers}
                            dealer={dealer}
                            actions={{
                                setDealer,
                                setUserAttribute,
                            }}
                        ></DealersList>
                    )}
                </section>
            </main>
        </div>
    );
};

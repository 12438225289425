import Keycloak from 'keycloak-js';
import { ApiGatewayClient, HttpMethod } from '@dh/keycloak-auth';

import { apiGatewayClientConfig } from './config';
import { DealershipType } from './utils/interfaces';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class AppService {
    protected client: ApiGatewayClient;

    public readonly postHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    /**
     * Instantiates a new ApiGatewayClient
     */
    constructor(keycloak: Keycloak) {
        apiGatewayClientConfig.authorization = keycloak.idToken;
        this.client = new ApiGatewayClient(apiGatewayClientConfig);
    }

    /**
     * Method to get the dealers catalog.
     * @returns list of associated dealers
     */
    public async getDealers(): Promise<Array<DealershipType>> {
        return this.client
            .fetch<Array<DealershipType>>({
                path: '/dealers',
                method: HttpMethod.GET,
            })
            .then((response) => response.data);
    }

    public handleError = (error) => {
        let message = error?.response?.data?.message || error.toString();
        if (Array.isArray(message)) {
            const constraints = message[0].constraints;
            if (constraints) message = JSON.stringify(constraints);
        }
        return message;
    };
}

import { useKeycloak } from '@react-keycloak/web';
import { BronsonSpinner, BronsonSpinnerProps } from '@dh/bronson-react';
import { getUserRole, UserDataInterface } from '@dh/keycloak-auth';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { AppContext } from '../context/app.context';
import { AppContextInterface } from '../utils/interfaces';
import { AppService } from '../App.service';
import { BronsonApp } from '../components/BronsonApp';
import ErrorPage from '../views/Error';
import FooterLink from '../views/Footer';
import { FooterPages } from '../views/Footer/footer.enum';
import { insuranceRoles } from '../config';
import Login from '../views/login';
import { PortalRoutes } from './routes.config';
import { PrivateRoute } from './utils';
import { registerLabels } from '../views/Register/register.labels';
import { UserType } from '../utils/enums';
import { zero } from '../utils/constants';
import { forbiddenLabels, notFoundLabels } from '../views/Error/error.labels';

export const AppRouter = () => {
    const { keycloak, initialized } = useKeycloak();
    // keycloak token
    const userName = keycloak?.tokenParsed?.sub;
    const email = keycloak?.idTokenParsed?.['email'];
    const [loading, setLoading] = useState(true);

    const [appInfo, setAppInfo] = useState({} as AppContextInterface);
    const [userData, setUserData] = useState<UserDataInterface>();

    useEffect(() => {
        if (appInfo.userInfo?.type) {
            switch (appInfo.userInfo.type) {
                case UserType.WOR:
                case UserType.ENF:
                case UserType.DNA:
                    window.location.href = '#/register';
                    break;
            }
        }
    }, [appInfo]);

    useEffect(() => {
        if (initialized) {
            const appService = new AppService(keycloak);
            const role = getUserRole(keycloak, insuranceRoles);
            if (userName && !keycloak?.authenticated) {
                setLoading(false);
                window.location.href = '#/login';
            } else if (userName && keycloak?.authenticated) {
                setUserData({ role, id: userName });
                appService
                    .getDealers()
                    .then((dealersResponse) => {
                        setLoading(false);
                        //USER DOESN'T HAVE A ROLE AND ASK FOR SELLER ROLE
                        if (!role && dealersResponse.length === zero) {
                            setAppInfo({
                                userInfo: {
                                    id: userName,
                                    type: UserType.WOR,
                                    dealers: dealersResponse,
                                    email,
                                },
                            });
                        }
                        //USER HAS A ROLE
                        else {
                            setAppInfo({
                                userInfo: {
                                    id: userName,
                                    type: UserType.NOR,
                                    dealers: dealersResponse,
                                    email,
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        const message =
                            error?.response?.data?.message || error.toString();
                        const errorList = Object.values(
                            registerLabels.serviceErrors,
                        );
                        //IF DOMAIN ISN'T ALLOWED OR EMAIL NOT FOUND IN DEALERS DIRECTORY
                        if (error && errorList.includes(message)) {
                            setAppInfo({
                                userInfo: {
                                    id: userName,
                                    type:
                                        registerLabels.serviceErrors
                                            .domainNotAllowed === message
                                            ? UserType.DNA
                                            : UserType.ENF,
                                    dealers: [],
                                    email,
                                },
                            });
                        }
                    });
            } else {
                setLoading(false);
            }
        }
    }, [initialized, userName, keycloak]);

    if (initialized && !loading) {
        return (
            <AppContext.Provider value={appInfo}>
                <Router basename='/'>
                    <BronsonApp userData={userData} loading={loading}>
                        <Switch>
                            {PortalRoutes.map((elem, index) => (
                                <PrivateRoute
                                    key={index}
                                    component={elem.component}
                                    path={elem.path}
                                    exact={elem.exact ? elem.exact : false}
                                    allowedRoles={
                                        elem.allowedRoles
                                            ? elem.allowedRoles
                                            : undefined
                                    }
                                ></PrivateRoute>
                            ))}
                            <Route path='/terms-and-conditions'>
                                <FooterLink page={FooterPages.GENERAL_TERMS} />
                            </Route>
                            <Route path='/forbidden'>
                                <ErrorPage {...forbiddenLabels} />
                            </Route>
                            <Route path='/login' component={Login} />
                            <Route>
                                <ErrorPage {...notFoundLabels} />
                            </Route>
                        </Switch>
                    </BronsonApp>
                </Router>
            </AppContext.Provider>
        );
    } else {
        const spinnerConfig: BronsonSpinnerProps = {
            showSpinner: true,
            spinnerWrapperClassModifier: 'c-spinner--text',
            showSpinnerText: true,
            spinnerText: 'Estamos configurando tu espacio de trabajo',
        };
        return <BronsonSpinner config={spinnerConfig}></BronsonSpinner>;
    }
};

import Keycloak from 'keycloak-js';
import { HttpMethod, Response } from '@dh/keycloak-auth';

import { AppService } from '../../App.service';

/**
 * Class responsible for manage the API calls and data request of the App
 */
export class DealersService extends AppService {
    public nameService: string;

    constructor(keycloak: Keycloak) {
        super(keycloak);
        this.nameService = 'DealersService';
    }

    /**
     * This method set attributes rol to user
     */
    public async setAttribute(id: string, attributes): Promise<Response> {
        return this.client.fetch({
            path: `/realm/dealers/add-attribute/user/${id}`,
            method: HttpMethod.PUT,
            body: attributes,
        });
    }
}

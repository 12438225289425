import React from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakImplementation, KeycloakParams } from '@dh/keycloak-auth';

import { AppRouter } from './routes';
import { keycloakConfig } from './config';

class App extends React.PureComponent {
    private keycloakParams: KeycloakParams = {
        keycloakConfig,
    };
    // keycloak implementation of the keycloak-js
    public keycloak = new KeycloakImplementation(this.keycloakParams);

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
        };
    }

    public render() {
        return (
            <ReactKeycloakProvider
                authClient={this.keycloak.instance}
                initOptions={this.keycloak.keycloakProviderInitConfig}
            >
                <AppRouter />
            </ReactKeycloakProvider>
        );
    }
}

export default App;
